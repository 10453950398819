<template>
  <div
    v-if="isReady"
    class="Measure"
    :class="computedClasses"
  >
    <ReportReadyNotifier />

    <BaseCoverageFilters class="is-hidden" />

    <CoverPage />

    <KeyMetricsByHfValue />

    <KeyMetricsByImpressions />

    <KeyMetricsByClippings />

    <FactsMarkets />

    <MediaTypeByHfValue />

    <MediaTypeByImpressions />

    <MediaTypeByClippings />

    <Branding />

    <MediaCategoryByHfValue />

    <MediaCategoryByImpressions />

    <MediaCategoryByClippings />

    <Sentiment />

    <MetricsMap />

    <TopClippings />

    <TopTags />

    <CoverageTopClippingsDataProvider
      #default="{ data }"
      :fetch-when="bootstrapped"
      :limit="300"
      class="Page__content-body"
      name="clipping_preview"
    >
      <template v-if="data.length">
        <ClippingsDivider />

        <ClippingPage
          v-for="clipping in data"
          :key="clipping.id"
          :clipping="clipping"
        />
      </template>
      <template v-else>
        <div />
      </template>
    </CoverageTopClippingsDataProvider>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import BaseCoverageFilters from '@hypefactors/shared/js/components/clippings/BaseCoverageFilters'
import CoverageTopClippingsDataProvider from '@hypefactors/shared/js/components/tables/CoverageTopClippingsDataProvider'
import ReportReadyNotifier from '@hypefactors/shared/js/components/notifiers/ReportReadyNotifier'

import { Trans } from '@/services/TranslationService'

// TODO: Rename the following components to have a stricter
// naming pattern since these are "pages" aka "sections".
import Branding from '@/components/measure/sections/Branding'
import ClippingsDivider from '@/components/measure/sections/ClippingsDivider' // TODO: This is an exception for renaming...
import ClippingPage from '@/components/measure/sections/ClippingPage'
import CoverPage from '@/components/measure/sections/CoverPage'
import FactsMarkets from '@/components/measure/sections/FactsMarkets'
import KeyMetricsByHfValue from '@/components/measure/sections/KeyMetricsByHfValue'
import KeyMetricsByClippings from '@/components/measure/sections/KeyMetricsByClippings'
import KeyMetricsByImpressions from '@/components/measure/sections/KeyMetricsByImpressions'
import MediaCategoryByHfValue from '@/components/measure/sections/MediaCategoryByHfValue'
import MediaCategoryByClippings from '@/components/measure/sections/MediaCategoryByClippings'
import MediaCategoryByImpressions from '@/components/measure/sections/MediaCategoryByImpressions'
import MediaTypeByHfValue from '@/components/measure/sections/MediaTypeByHfValue'
import MediaTypeByClippings from '@/components/measure/sections/MediaTypeByClippings'
import MediaTypeByImpressions from '@/components/measure/sections/MediaTypeByImpressions'
import MetricsMap from '@/components/measure/sections/MetricsMap'
import Sentiment from '@/components/measure/sections/Sentiment'
import TopClippings from '@/components/measure/sections/TopClippings'
import TopTags from '@/components/measure/sections/TopTags'

export default {
  name: 'View',

  components: {
    BaseCoverageFilters,
    CoverageTopClippingsDataProvider,
    ReportReadyNotifier,

    Branding,
    ClippingsDivider,
    ClippingPage,
    CoverPage,
    FactsMarkets,
    KeyMetricsByHfValue,
    KeyMetricsByClippings,
    KeyMetricsByImpressions,
    MediaCategoryByHfValue,
    MediaCategoryByClippings,
    MediaCategoryByImpressions,
    MediaTypeByHfValue,
    MediaTypeByClippings,
    MediaTypeByImpressions,
    MetricsMap,
    Sentiment,
    TopClippings,
    TopTags
  },

  data () {
    return {
      isReady: false
    }
  },

  computed: {
    ...mapState('coverage', ['bootstrapped']),
    ...mapGetters('measure', ['language', 'fontFamily']),

    computedClasses () {
      return {
        [`has-font-${this.fontFamily}`]: this.fontFamily
      }
    }
  },

  async created () {
    const loading = this.$loading({
      lock: true,
      text: this.$t('Preparing the Report')
    })

    await Trans.changeLanguageWithoutPersisting(this.language)

    this.isReady = true

    loading.close()
  }
}
</script>
