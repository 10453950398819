<template>
  <Page
    :sidebar-title="$t('pages.measure.top_tags.sidebar_title', language)"
    class="TopTags"
    :sidebar-content="$t('pages.measure.top_tags.sidebar_0', language, {brand: brand.name})"
    :name="key"
    data-testid="TopTags"
  >
    <PageTitle>{{ $t('pages.measure.top_tags.title', language) }}</PageTitle>

    <CoverageTopTagsDataProvider
      #default="{ data: tags }"
      :fetch-when="bootstrapped"
      class="Page__content-body"
    >
      <TopTagsChart :tags="tags" />
    </CoverageTopTagsDataProvider>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import CoverageTopTagsDataProvider from '@hypefactors/shared/js/components/tables/CoverageTopTagsDataProvider'
import TopTagsChart from '@hypefactors/shared/js/components/charts/TopTags'

import BaseSection from './BaseSection'
import Page from '@/components/measure/Page'
import PageTitle from '@/components/measure/PageTitle'

export default {
  name: 'TopTags',

  components: {
    Page,
    PageTitle,
    TopTagsChart,
    CoverageTopTagsDataProvider
  },

  extends: BaseSection,

  data () {
    return {
      key: 'top_clippings'
    }
  },

  computed: {
    ...mapGetters('measure', [
      'language'
    ])
  }
}
</script>
